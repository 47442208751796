import { default as _91_46_46_46slug_93HY68mNZqTYMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexNAs3xrzV8kMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addressesGfRHXYfDHFMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyFjOv4sEMTOMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactl01KNfD8uLMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexctF3T6PaUpMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsXtDoB9ZmlfMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_933Mf6GsVCp1Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as orders9YGlRTKOCkMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billing9Nu4z0Ncs6Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userbdEnxCsxbUMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editGalcdA1mk3Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexpxsAVjPaDKMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newC37hyTCQmwMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editZ93e19GJBcMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexzerAjW9aAsMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profileA7LRYIFC0bMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountju3bphwoGvMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93NAZMA9lMzOMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as category6Fe7ShYKHjMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cart62BbL6iCTQMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentrsvVqGk5zxMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryjEFZfb6wPIMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutGJx6Cxy8PwMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordst464ObwriMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordej5aqriNDyMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexxy0PepYK7TMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexA0RAtkZJ1CMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productQMT4SBFj7YMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93CGBtzlrxh7Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as indexuoVx55gl3bMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registerSp2V2oIRlwMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolvegRSkGZ94FSMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmsQCYyOvwxmcMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storemisdfTFAtuMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchnqLpRKneHdMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSection4W7GmRPbCoMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectionCqvrjhNhuVMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexz9nAMmk2yAMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClient3RDIWsYm21Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementCV64wIONJxMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementH4PEHo4aHTMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionxz2BGYbiz5Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeadern7lu2iaYl6Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionUTAFewtl7tMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugVHTfx9QwFAMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormp3H9E41S2wMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModaloU8jS8lFRoMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickygyQ7bqHPGDMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaoMwNFhSms6Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftrVb40S5fEAMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightA8TCl4yxmpMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationzceNloTaa3Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionJoShn6mb9SMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesUe0wm0s5a0Meta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSection0biThuzGgyMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationxSdlPbP1wwMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightTg2TiwUXDzMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as index8nw7FRdSpsMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsaz1fFh1PhNMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPagey9xaVfCTTKMeta } from "/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageXlwNjulVGJMeta } from "/home/ecomadm/app/releases/44/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93HY68mNZqTYMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-cms/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountju3bphwoGvMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account.vue").then(m => m.default || m),
    children: [
  {
    name: addressesGfRHXYfDHFMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesGfRHXYfDHFMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: companyFjOv4sEMTOMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyFjOv4sEMTOMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/company.vue").then(m => m.default || m)
  },
  {
    name: contactl01KNfD8uLMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactl01KNfD8uLMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: listsXtDoB9ZmlfMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsXtDoB9ZmlfMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/lists.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: orders9YGlRTKOCkMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: orders9YGlRTKOCkMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payments_45and_45billing9Nu4z0Ncs6Meta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billing9Nu4z0Ncs6Meta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userbdEnxCsxbUMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editGalcdA1mk3Meta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexpxsAVjPaDKMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newC37hyTCQmwMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editZ93e19GJBcMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexzerAjW9aAsMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexzerAjW9aAsMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue").then(m => m.default || m)
  },
  {
    name: profileA7LRYIFC0bMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profileA7LRYIFC0bMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/category.vue").then(m => m.default || m),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93NAZMA9lMzOMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkoutGJx6Cxy8PwMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutGJx6Cxy8PwMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: cart62BbL6iCTQMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cart62BbL6iCTQMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentrsvVqGk5zxMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryjEFZfb6wPIMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordst464ObwriMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordej5aqriNDyMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/event/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productQMT4SBFj7YMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/product.vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93CGBtzlrxh7Meta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: indexuoVx55gl3bMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue").then(m => m.default || m)
  },
  {
    name: registerSp2V2oIRlwMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerSp2V2oIRlwMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-customer/pages/[locale]/register.vue").then(m => m.default || m)
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolvegRSkGZ94FSMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/resolve.vue").then(m => m.default || m)
  },
  {
    name: searchnqLpRKneHdMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchnqLpRKneHdMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/search.vue").then(m => m.default || m),
    children: [
  {
    name: cmsQCYyOvwxmcMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmsQCYyOvwxmcMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/search/cms.vue").then(m => m.default || m)
  },
  {
    name: storemisdfTFAtuMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storemisdfTFAtuMeta || {},
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-ecom/pages/[locale]/search/store.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/44/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/44/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue").then(m => m.default || m)
  }
]